import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Flex } from "reflexbox/styled-components";
import AppButton from "../button";
import StrokedBox from "../stroked-box";

const Root = styled.div(({ theme }) => ({
  width: "70%",
  marginLeft: "15%",
  [theme.mq.tablet]: {
    width: "90%",
    maxWidth: 900,
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const Box = styled(StrokedBox)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  alignItems: "center",
  minHeight: 330,
  paddingBottom: 5,
  margin: `${theme.spacing(4)}px auto`,
  boxSizing: "border-box",
  [theme.mq.tablet]: {
    margin: `${theme.spacing(5)}px auto ${theme.spacing(8)}px auto`,
    flexDirection: "row",
    padding: `0 ${theme.spacing(3)}px`,
  },
}));

const CONTACT_BOX_QUERY = graphql`
  query ContactBox {
    markdownRemark(fileAbsolutePath: { regex: "/information.md/" }) {
      frontmatter {
        phone
        email
      }
    }
  }
`;

const ActionWrapper = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  margin: theme.spacing(1),
  flexDirection: "column",
  [theme.mq.tablet]: {
    flexDirection: "row",
  },
}));

const StyledActionButton = styled(AppButton)(({ theme }) => ({
  width: "90%",
  [theme.mq.tablet]: {
    width: 225,
  },
}));

const ActionText = styled.span(({ theme }) => ({
  display: "block",
  width: "100%",
  textAlign: "center",
  marginBottom: 10,
  [theme.mq.tablet]: {
    width: "30%",
    marginBottom: 0,
    textAlign: "left",
  },
}));

const ContentLeft = styled(Flex)(({ theme }) => ({
  "&&": {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
  },
  [theme.mq.tablet]: {
    marginLeft: 0,
    marginRight: 0,
  },
}));

const ContentRight = styled(Flex)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  width: "100%",
  [theme.mq.tablet]: {
    marginLeft: "8%",
    width: 280,
  },
}));

const H2 = styled.h2(({ theme }) => ({
  textAlign: "center",
  color: theme.colors.secondary.main,
  marginBottom: 0,
  [theme.mq.tablet]: {
    textAlign: "left",
  },
}));

const P = styled.p(({ theme }) => ({
  textAlign: "center",
  fontWeight: 200,
  fontSize: 16,
  width: "90%",
  marginLeft: "5%",
  [theme.mq.tablet]: {
    width: "80%",
    textAlign: "left",
    marginLeft: 0,
  },
}));

const Strong = styled.strong(({ theme }) => ({
  textAlign: "center",
  fontWeight: 400,
  fontSize: 16,
  width: "80%",
  marginLeft: "10%",
  [theme.mq.tablet]: {
    textAlign: "left",
    width: "unset",
    marginLeft: 0,
  },
}));

const AppContactBox = () => {
  const theme = useTheme();

  const { markdownRemark } =
    useStaticQuery<GatsbyTypes.ContactBoxQuery>(CONTACT_BOX_QUERY);
  return (
    <Root>
      <Box secondary perspective="front" light>
        <ContentLeft flexDirection="column" flex={2}>
          <H2>Brug for juridisk bistand?</H2>
          <P>
            Send en besked, eller ring, og lad os tage en snak om dine
            muligheder
          </P>
          <Strong>Jeg ser frem til at høre fra dig</Strong>
        </ContentLeft>
        <ContentRight flexDirection="column">
          <ActionWrapper>
            <ActionText>Tlf:</ActionText>
            <StyledActionButton
              component="a"
              href={`tel:${markdownRemark?.frontmatter?.phone}`}
            >
              {markdownRemark?.frontmatter?.phone}
            </StyledActionButton>
          </ActionWrapper>
          <ActionWrapper>
            <ActionText>Email:</ActionText>
            <StyledActionButton
              component="a"
              href={`mailto:${markdownRemark?.frontmatter?.email}`}
            >
              {markdownRemark?.frontmatter?.email}
            </StyledActionButton>
          </ActionWrapper>
        </ContentRight>
      </Box>
    </Root>
  );
};

export default AppContactBox;
