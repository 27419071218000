import { css, SerializedStyles, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React, { CSSProperties } from "react";

interface Props {
  secondary?: boolean;
  className?: string;
  gradient?: boolean;
  light?: boolean;
  perspective?: "left" | "right" | "front";
}

const StrokedBox: React.FC<Props> = ({
  children,
  secondary,
  className,
  perspective,
  gradient,
  light,
}) => {
  const { colors, spacing } = useTheme();
  const color = secondary ? colors.secondary : colors.primary;
  const offset = spacing(1);

  const bg: CSSProperties = gradient
    ? {
        background: `linear-gradient(180deg, ${color.light} 0%, ${color.main} 100%)`,
      }
    : {
        backgroundColor: light ? color.light : color.main,
      };

  return (
    <div
      className={className}
      css={css({
        position: "relative",
        ...bg,
        width: "100%",
        "&::after": {
          left: 0,
          position: "absolute",
          top: 0,
          zIndex: -1,
          display: "block",
          content: "''",
          height: "100%",
          width:
            perspective === "front" ? `calc(100% + ${offset * 2}px)` : "100%",
          backgroundColor: "transparent",
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: light ? color.light : color.main,
          transform: `translate(${
            perspective === "left" ? offset : -offset
          }px, ${-offset}px)`,
        },
      })}
    >
      {children}
    </div>
  );
};

export default StrokedBox;
